/* Content.css */
.risk-assessment {
    margin-top: 1.5em;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .risk-item {
    margin-bottom: 0px 16px 16px 16px;
    text-align: center;
    flex: 1;
  }
  
  .category-label {
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .circle-chart {
    width: 80px;
    height: 80px;
    transform: rotate(-90deg);
  }
  
  .circle-bg {
    fill: none;
    stroke: #eee;
    stroke-width: 3.8;
  }
  
  .circle {
    fill: none;
    stroke-width: 3.8;
    stroke-linecap: round;
    transition: stroke-dasharray 2s ease, stroke 2s ease;
  }
  
  .risk-label {
    margin-top: 10px;
    font-size: 14px;
  }
  